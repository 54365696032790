import React from "react";
import streamlineImg from "../assets/images/png/Streamline-img.png";

const Streamline = () => {
  return (
    <>
      <div className="mb-5 mb-lg-0 pb-md-5 pb-lg-0">
        <div className="container pb-5">
          <div className="streamline_bg ms-lg-4">
            <div className="row justify-content-center justify-content-lg-start mb-md-5 pb-md-4 mb-lg-0 pb-lg-0">
              <div className="col-lg-6">
                <div className="building_box   ">
                  <h3 className="font_xl fw-normal color_white ff_gilroy_normal mb-0 mb-xl-4 pb-3">
                    Building strong lasting
                    <span className="font_xl fw-bold ff_gilroy_bold d-inline-block ms-md-2 ms-lg-0 me-2">
                      partnerships
                    </span>
                    with
                    <span className="font_xl fw-bold ff_gilroy_bold d-inline-block ms-2 ms-md-0 me-2 mx-xl-2">
                      trust
                    </span>
                    <br className="d-none d-xl-inline-block" />
                    and&nbsp;
                    <span className="font_xl fw-bold ff_gilroy_bold d-inline-block">
                      transparency
                    </span>
                  </h3>
                  <p className="font_sm fw-light color_white ff_gilroy_light lh_142   mb-0 ">
                    Shaping the future of fieldwork, participant recruitment,
                    and data services utilising cutting-edge technology. We
                    provide in-house services for the UK & Ireland and have
                    developed Streamline for all your international projects.
                  </p>
                  <p className="font_sm fw-light color_white ff_gilroy_light lh_142 pt-4">
                    We are revolutionising the fieldwork industry with a
                    refreshing approach that meets the highest standards of
                    quality, fulfilling the demands of your project. Trust us to
                    provide the elite services that your project deserves.
                  </p>
                  <span className="border_linear_gradient mb-lg-4 mb-xxl-5 w-100 d-inline-block d-none d-lg-block"></span>
                  <h3 className="font_lg fw-light color_white ff_gilroy_light pt-md-3 mb-0">
                    Introducing
                  </h3>
                  <h2 className="font_2xl fw-light color_white ff_gilroy_light ">
                    Streamline
                  </h2>
                  <p className="font_sm fw-light color_white ff_gilroy_light lh_142 mb-0  pe-xl-4">
                    Unlocking the world to our clients with our vetted global
                    partners. Receive transparent international quotes within
                    your timeframe.
                  </p>
                </div>
              </div>
              <div className="col-sm-10 col-lg-6">
                <div className="streamline_img mx-auto">
                  <img
                    className="w-100 h-100 object_fit_cover rounded_border"
                    src={streamlineImg}
                    alt="streamline-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Streamline;
