import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useScreenFixedProvider } from "../src/components/context/ScreenFixedProvider";
import Home from "./pages/Home";
import Industry from "./pages/Industry";

function App() {
  const { showOverlay } = useScreenFixedProvider();
  useEffect(() => {
    if (showOverlay) {
      document.body.classList.add("setClass");
    } else {
      document.body.classList.remove("setClass");
    }
  }, [showOverlay]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/industries" element={<Industry />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
