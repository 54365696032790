import React, { useLayoutEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import IndustryOverview from "./Industry-streamline-tab/IndustryOverview";
import IndustryFeatures from "./Industry-streamline-tab/IndustryFeatures";
import IndustryPricing from "./Industry-streamline-tab/IndustryPricing";

const IndustryStreamLine = () => {
  useLayoutEffect(() => {
    let industrtstreamline = gsap.timeline({
      scrollTrigger: {
        trigger: ".industry_streamline_parent",
        start: "top 100%",
        end: "bottom center",
      },
    });
    industrtstreamline
      .from(".industry_streamline_text", {
        xPercent: -100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(2)",
      })
      .from(
        ".industry_streamline_para",
        {
          xPercent: 100,
          autoAlpha: 0,
          duration: 1,
          ease: "back(2)",
        },
        "=-.6"
      );
  }, []);
  const [streamkey, setStreamKey] = useState("home");
  return (
    <>
      <div
        className="container mb-5 pb-md-4 industry_streamline_parent"
        id="streamline"
      >
        <div className="rounded_border streamline_bg_gradient">
          <div className="industries_streamline_box">
            <div className="industry_streamline_text">
              <h2 className="fs_2xl fw-light ff_gilroy_light color_white pb-lg-4 mb-0">
                Streamline
              </h2>
            </div>
            <div className="position-relative indutry_stream_line_bg_img my-5 mx-auto rounded_border"></div>
            <div className="row mb-lg-5 m-auto">
              <div className="col-sm-12 mx-auto">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={streamkey}
                  onSelect={(k) => setStreamKey(k)}
                  className="text-nowrap industry_solution_tabs align-items-center industry_streamline_btn justify-content-center justify-content-sm-between"
                >
                  <Tab
                    eventKey="home"
                    title="Overview"
                    className="industry_stream_tab"
                  >
                    <IndustryOverview />
                  </Tab>
                  <Tab eventKey="Features" title="Features">
                    <IndustryFeatures />
                  </Tab>
                  <Tab eventKey="Pricing" title="Pricing">
                    <IndustryPricing />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className=" stream_line_bg_s ec">
            <div className="container"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustryStreamLine;
