import React from "react";
import StreamLineImg from "../../assets/images/png/stream-line-tab-img.png";
import Enterprise from "../Enterprise";
import anextraservice from "../../assets/images/png/long-sleeve-tee-mockup-of-a-woman.png";
const Pricing = () => {
  return (
    <>
      <div className="pt-2 pt-sm-5 py-md-5 overview_sec">
        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex ms-md-5 features_content">
              <div className="line012 me-3"></div>
              <h3 className="font_xl fw-light open_sans mb-5 pb-sm-5">
                Unlocking the Value of Insights: Our <br className="d-none d-md-block"/> Pricing Options
              </h3>
            </div>
          </div>
        </div>
        <div className="row position-relative justify-content-center priceing_bg_blue py-5 py-lg-0 mt-4">
          <div className="col-md-6 col-lg-6 col-xl-5 col-xxl-4 mt_minus">
            <div className="popular_bg mb-2 mt-sm-4 mt-md-0">
              <div className="fold_populer_box text-center">
                <p className="ff_gilroy_medium font_lg text-white mb-1">
                  Very POPULAR
                </p>
                <p className="text-white font_sm mb-0 pb-4">
                  £ <span className="font_xl">0</span>
                  <span className="opacity_07 font_xs">/month</span>
                </p>
              </div>
              <div className="popular_card_content">
                <p className="ff_gilroy_medium mb-1 font_sm text-black mt-5">
                  Unlimited Quotes
                </p>
                <p className="ff_gilroy_medium opacity_07 text-black">
                  We will Streamline all RFQs from our vetted international
                  fieldwork partners within your timeframe.
                </p>
                <a
                  href="#contactus"
                  className="submit_btn text-decoration-none ff_gilroy_medium font_md d-inline-block mb-3"
                >
                  Submit your RFQ
                </a>
                <p className="ff_gilroy_medium opacity_07 text-black mb-0">
                  <span className="color_extra_light_blue">*</span>The quotes
                  that we provide are transparent. We strive to provide you with
                  the best rate and quality, giving you the opportunity to save
                  resources on every project.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-5 col-xxl-4 mt_minus">
            <div className="popular_bg mb-2 mt-4 mt-md-0">
              <div className="fold_populer_box text-center">
                <p className="ff_gilroy_medium font_lg text-white mb-1">
                  Most POPULAR
                </p>
                <p className="text-white font_sm mb-0 pb-4">
                  £ <span className="font_xl">180</span>
                  <span className="opacity_07 font_xs">/country</span>
                </p>
              </div>
              <div className="popular_card_content">
                <p className="ff_gilroy_medium mb-1 font_sm text-black mt-5">
                  Kick-off
                </p>
                <p className="ff_gilroy_medium opacity_07 text-black">
                  When commissioned for a Streamline project, all aspects of
                  your international endeavors will be managed and coordinated
                  by us.
                </p>
                <a
                  href="#contactus"
                  className="submit_btn text-decoration-none ff_gilroy_medium font_md d-inline-block mb-3"
                >
                  Submit your RFQ
                </a>
                <p className="ff_gilroy_medium opacity_07 text-black mb-0">
                  <span className="color_extra_light_blue">*</span>We won’t
                  charge you the Streamline fees for projects cantered in the UK
                  & Ireland.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row position-relative justify-content-evenly priceing_bg_blue priceing_bg_blue2 py-5 py-lg-0">
          <div className="col-lg-5">
            <div className=" py-lg-5 ">
              <h3 className="font_xl pb-2 ff_gilroy_light color_white fw-light open_sans mb-0 ">
                An extra service for you
              </h3>
              <div className="mt-4 mt-lg-0">
                <img
                  src={anextraservice}
                  alt="anextraservice"
                  className="feature_tab_img"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-5 col-xxl-4 mt_minus2">
            <div className="popular_bg mb-2">
              <div className="fold_populer_box text-center">
                <p className="ff_gilroy_medium font_lg text-white mb-1">
                  Popular
                </p>
                <p className="text-white font_sm mb-0 pb-4">
                  £ <span className="font_xl">799</span>
                  <span className="opacity_07 font_xs">/month</span>
                </p>
              </div>
              <div className="popular_card_content2">
                <p className="ff_gilroy_medium mb-1 font_sm text-black mt-5">
                  Quote Management
                </p>
                <p className="ff_gilroy_medium opacity_07 text-black">
                  Do you want us to help you manage your quotes with your
                  existing partners? We will deliver analysis on the best bids,
                  giving you the opportunity to focus on the bigger picture.
                </p>
                <a
                  href="#contactus"
                  className="submit_btn text-decoration-none ff_gilroy_medium font_md d-inline-block mb-3"
                >
                  Get in touch
                </a>
                <p className="ff_gilroy_medium opacity_07 text-black mb-0">
                  <span className="color_extra_light_blue">*</span>Forward your
                  requests to us and let us handle the rest. We will send them
                  to your partners, track their timely return, and provide daily
                  summary reports, including partners' feasibility. Our analysis
                  will determine the best partner for your project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-xl-5">
        <Enterprise />
      </div>
    </>
  );
};

export default Pricing;
