import React, { useLayoutEffect } from "react";
import HealthTrainer from "../assets/images/png/Pharmaceutical-img.png";
import BoxCenterLine from "../assets/images/png/BoxCenterLine.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
const Pharmaceutical = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let healthcare = gsap.timeline({
          scrollTrigger: {
            trigger: ".healthcare_sec",
            start: "top 30%",
            end: "center center",
            scrub: 1,
          },
        });
        healthcare.fromTo(
          ".healthcare_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "300px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:320px) and (max-width:991px)": function () {
        let healthcare = gsap.timeline({
          scrollTrigger: {
            trigger: ".healthcare_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        healthcare.fromTo(
          ".healthcare_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    let pharmace = gsap.timeline({
      scrollTrigger: {
        trigger: ".healthcare_sec",
        start: "top 50%",
        end: "center center",
        scrub: 1,
      },
    });
    pharmace
      .fromTo(
        ".healthcare_text",
        {
          xPercent: -100,
          duration: 1,
        },
        {
          xPercent: "10",
        }
      )
      .fromTo(
        ".pharmaceutical_img",
        {
          top: "95%",
        },
        {
          top: "25%",
        }
      );
  }, []);
  return (
    <>
      <section className="pt-4 pb-lg-5 healthcare_sec " id="healthcare">
        <div className="position-relative border_rounded_custom_all_images_Mobile healthcare_bg d-flex justify-content-start mt-5 mx-auto">
          <p className="font_2xl fw_500 ff_gilroy_medium text-white position-absolute health_p_pl healthcare_text">
            Healthcare
          </p>
        </div>
        <div className="container position-relative">
          <div className="row pt-5 pt-lg-5 mt-lg-3">
            <div className="col-sm-11 col-md-11 col-lg-12 col-xl-11 m-auto">
              <div className="bg_blue rounded_border position-relative">
                <img
                  className="position-absolute  pharama_box_center_line d-none d-lg-block"
                  src={BoxCenterLine}
                  alt="center_line"
                />
                <img
                  className="position-absolute draw_box_center_line d-none d-lg-block"
                  src={BoxCenterLine}
                  alt="center_line"
                />
                <div className="row">
                  <div className="col-sm-11 mx-auto col-lg-7 mx-auto pt-sm-4 pt-lg-0">
                    <div className="pt-5 ms-lg-5 ps-lg-5">
                      <p className="font_lg text-white ff_gilroy_normal px-4 px-md-3 px-lg-0 ">
                        Drawing on our extensive experience in the Healthcare
                        and Pharmaceutical sectors enables us to deliver a
                        wealth of knowledge to our clients.
                      </p>
                    </div>
                    <div className="position-absolute pharmaceutical_img Pharmaceuticalimg_img_positioning img_max_w d-none d-lg-block">
                      <img
                        className="w-100 rounded_border_images"
                        src={HealthTrainer}
                        alt="Pharmaceuticalimg"
                      />
                    </div>
                    <div className="d-lg-none text-center px-4 px-lg-0">
                      <img
                        className="w-100 img_m ax_w rounded_border_images"
                        src={HealthTrainer}
                        alt="Pharmaceuticalimg"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex flex-column align-items-lg-center">
                    <div className="py-4 py-lg-5 mb-xl-5 mt-xl-3 mx-4 px-md-3 mx-lg-0 px-lg-0">
                      <div className="pb-3">
                        <h3 className="font_xl color_white fw-normal ff_gilroy_normal mb-0">
                          Pharmaceutical &
                        </h3>
                        <p className="pb-3 pb-lg-4 m-0 font_lg color_white fw-normal ff_gilroy_normal">
                          Biotechnology
                        </p>
                        <ul className="pt-1 ps-4 mb-0">
                          <li className="main_para ff_gilroy_normal">
                            Biotechnology
                          </li>
                          <li className="main_para pt-3 ff_gilroy_normal">
                            Life Science Tools & Services
                          </li>
                          <li className="main_para pt-3 ff_gilroy_normal">
                            Pharmaceuticals
                          </li>
                        </ul>
                      </div>
                      <div className="pt-2 pt-lg-4">
                        <h3 className="font_xl color_white fw-normal ff_gilroy_normal mb-0">
                          Healthcare &
                        </h3>
                        <p className="pb-3 pb-lg-4 m-0 font_lg color_white fw-normal ff_gilroy_normal">
                          Equipment
                        </p>
                        <ul className="pt-1 ps-4 mb-0">
                          <li className="main_para ff_gilroy_normal">
                            Healthcare Equipment and Supplies
                          </li>
                          <li className="main_para pt-3 ff_gilroy_normal">
                            Healthcare Provider and Services
                          </li>
                          <li className="main_para pt-3 ff_gilroy_normal">
                            Healthcare Technology
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pharmaceutical;
