import React, { useLayoutEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Overview from "./Streamline-tab/Overview";
import Features from "./Streamline-tab/Features";
import Pricing from "./Streamline-tab/Pricing";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

const StreamlineTabs = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let streamlineparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".stream_line_bg_sec",
            start: "top 80%",
            end: "center center",
            scrub: 1,
          },
        });
        streamlineparent.fromTo(
          ".stream_line_bg_img",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:320px) and (max-width:991px)": function () {
        let streamlineparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".stream_line_bg_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        streamlineparent.fromTo(
          ".stream_line_bg_img",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "250px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "150px",
          }
        );
      },
    });
    let streamlinetext = gsap.timeline({
      scrollTrigger: {
        trigger: ".stream_line_bg_sec",
        start: "top bottom",
        end: "center bottom",
        scrub: 1,
      },
    });
    streamlinetext.fromTo(
      ".streamline_text",
      {
        xPercent: -40,
        duration: 1,
      },
      {
        xPercent: "20",
      }
    );
  }, []);
  const [streamkey, setStreamKey] = useState("home");
  return (
    <>
      <div className="py-lg-5 stream_line_bg_sec">
        <div
          className="position-relative stream_line_bg_img my-5 mx-auto"
          id="streamline">
          <p
            eventKey="Fieldwork"
            className="font_2xl fw_500 ff_gilroy_medium text-white position-absolute health_p_pl streamline_text">
            Streamline
          </p>
        </div>
        <div className="container">
          <div className="row pt-xl-4 mb-5">
            <div className="col-sm-12 mx-auto mt-2">
              <Tabs
                id="controlled-tab-example"
                activeKey={streamkey}
                onSelect={(k) => setStreamKey(k)}
                className="mb-1 ms-sm-5 text-nowrap solution_tabs mb-0 justify-content-center justify-content-sm-start">
                <Tab eventKey="home" title="Overview">
                  <Overview />
                </Tab>
                <Tab eventKey="Fieldwork" title="Features">
                  <Features />
                </Tab>
                <Tab eventKey="Data" title="Pricing">
                  <Pricing />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamlineTabs;
