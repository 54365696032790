import React from "react";

const CorePerformance = () => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-center text-center flex-column core_executive_max_w">
          <h3 className="fw-light ff_gilroy_light font_xl color_white m-0">
            Core-Performance Director
          </h3>
          <p className="fw-light ff_gilroy_light color_white font_sm pt-2 pt-lg-4 mt-lg-1 m-0 mx-3 mx-lg-5 px-xl-5">
            Our Core-Performance Director serves as the internal leader and the
            client's "eagle eye" for the project
          </p>
          <div className="row pt-4 pt-lg-5">
            <div className="col-md-6">
              <h4 className="text-nowrap color_white fw-light ff_gilroy_light font_md">
                Core-Performance&nbsp;
                <span className="fw_500 ff_gilroy_medium">Manager</span>
              </h4>
              <p className="fw-light ff_gilroy_light color_white font_sm pt-2 pt-lg-3 px-4 px-xl-5">
                Our Core-Performance Manager is the client's project point of
                contact, responsible for overall performance.
              </p>
            </div>
            <div className="col-md-6 pt-2 pt-md-0">
              <h4 className="text-nowrap color_white fw-light ff_gilroy_light font_md">
                Core-Performance&nbsp;
                <span className="fw_500 ff_gilroy_medium">Recruiter</span>
              </h4>
              <p className="fw-light ff_gilroy_light color_white font_sm pt-2 pt-lg-3  px-2 px-xl-5">
                Our internal recruiter ensuring high performance during the
                recruitment process of the project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorePerformance;
