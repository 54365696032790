import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Next-page/Hero";
import Industries from "../components/Next-page/Industries";
import IndustryCore from "../components/Next-page/IndustryCore";
import IndustryGetInTouch from "../components/Next-page/IndustryGetInTouch";
import IndustryProjectJourney from "../components/Next-page/IndustryProjectJourney";
import IndustrySolution from "../components/Next-page/IndustrySolution";
import IndustryStreamLine from "../components/Next-page/IndustryStreamLine";
import BackToTop from "../components/common/BackToTop";

const Industry = () => {
  return (
    <>
      <Hero />
      <Industries />
      <IndustrySolution />
      <IndustryCore />
      <IndustryProjectJourney />
      <IndustryStreamLine />
      <IndustryGetInTouch />
      <Footer />
      <BackToTop />
    </>
  );
};

export default Industry;
