import React from "react";
import { TabData } from "../common/Helper";

const SolutionsTab = ({ setShowtab, showtab }) => {
  return (
    <>
      <div className="d-flex  flex-column flex-lg-row align-items-end align-items-lg-center justify-content-between">
        <div className="d-flex w-100 overflow-auto ps-md-5 ms-md-4 pb-2">
          {TabData &&
            TabData.map((item, index) => {
              return (
                <a
                  key={index}
                  onClick={() => setShowtab(item)}
                  className={`${
                    item === showtab
                      ? "solutions_tabs fw_600 color_white"
                      : "color_black fw-light solutions_tabs_2"
                  } text-decoration-none font_sm open_sans position-relative me-1 cursor_pointer text-nowrap`}
                >
                  {item.tabHeading}
                </a>
              );
            })}
        </div>
      </div>
      <div className="mb-3">{showtab.content}</div>
    </>
  );
};

export default SolutionsTab;
