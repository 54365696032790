import React, { useLayoutEffect, useState } from "react";
import map from "../assets/images/png/form_map.png";
import map2 from "../assets/images/png/map2.png";
import phone from "../assets/images/svg/phone.svg";
import mail from "../assets/images/svg/mail.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import emailjs, { init } from "@emailjs/browser";
// import SolutionImghome from "../assets/images/png/solution-tab-home.png";
import SolutionImghome from "../assets/images/png/feature_tab_new_img.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

init("hqIkri0diF2ZF3hS6");
const ContactForm = () => {
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let projectparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".get_sec",
            start: "top 50%",
            end: "center center",
            scrub: 1,
          },
        });
        projectparent.fromTo(
          ".touch_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:320px) and (max-width:991px)": function () {
        let projectparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".get_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        projectparent.fromTo(
          ".touch_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "250px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "150px",
          }
        );
      },
    });
    let panel = gsap.timeline({
      scrollTrigger: {
        trigger: ".get_sec",
        start: "top bottom",
        end: "center bottom",
        scrub: 1,
      },
    });
    panel.fromTo(
      ".panel_img_1",
      {
        scale: 0,
      },
      {
        scale: 1,
      },
      "=-.6"
    );
    let projectext = gsap.timeline({
      scrollTrigger: {
        trigger: ".get_sec",
        start: "top bottom",
        end: "center bottom",
        scrub: 1,
      },
    });
    projectext.fromTo(
      ".get_touch",
      {
        xPercent: -50,
        duration: 1,
      },
      {
        xPercent: "20",
      }
    );
  }, []);
  const initailState = {
    name: "",
    email: "",
    number: "",
    job: "",
    company: "",
    message: "",
  };

  const [contactFormData, setContactFormData] = useState(initailState);
  const [error, setError] = useState(false);
  const handleSubmitContactForm = (event) => {
    event.preventDefault();
    setError(true);
    if (
      contactFormData.name !== "" &&
      contactFormData.number !== "" &&
      contactFormData.email !== "" &&
      contactFormData.job !== "" &&
      contactFormData.company !== "" &&
      contactFormData.message !== ""
    )
      emailjs
        .send("service_o9no54l", "template_nwfo0l9", {
          name: contactFormData.name,
          number: contactFormData.number,
          email: contactFormData.email,
          job: contactFormData.job,
          company: contactFormData.company,
          message: contactFormData.message,
        })
        .then(() => {
          toast("Thank you for submission!");
          setContactFormData(initailState);
          setError(false);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="pb-sm-4 get_sec pt-5 pt-md-0" id="contactus">
        <div className="position-relative touch_bg mt-5 mx-auto ">
          <p className="font_2xl fw_500 ff_gilroy_medium text-white position-absolute health_p_pl get_touch">
            Get In Touch
          </p>
        </div>
        <div className="container mt_6rem bg_blue mb-xl-5 rounded_border_images position-relative">
          <div className="row justify-content-between contact_form_bg">
            <div className="col-lg-6">
              <form onSubmit={(event) => handleSubmitContactForm(event)}>
                <div>
                  <input
                    value={contactFormData.name}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        name: event.target.value,
                      })
                    }
                    type="text"
                    placeholder="Name*"
                    className="font_md ff_gilroy_light text-white input_box w-100 mb-3"
                  />

                  {error && contactFormData.name === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    value={contactFormData.email}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        email: event.target.value,
                      })
                    }
                    type="email"
                    placeholder="Email*"
                    className="font_md ff_gilroy_light text-white input_box w-100 mb-3"
                  />{" "}
                  {error && contactFormData.email === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    value={contactFormData.number}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        number: event.target.value,
                      })
                    }
                    type="number"
                    placeholder="Phone Number*"
                    className="font_md ff_gilroy_light text-white input_box w-100 mb-3"
                  />{" "}
                  {error && contactFormData.number === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    value={contactFormData.job}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        job: event.target.value,
                      })
                    }
                    type="text"
                    placeholder="Job Title*"
                    className="font_md ff_gilroy_light text-white input_box w-100 mb-3"
                  />{" "}
                  {error && contactFormData.job === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 mb-lg-5">
                  <input
                    value={contactFormData.company}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        company: event.target.value,
                      })
                    }
                    type="text"
                    placeholder="Company name*"
                    className="font_md ff_gilroy_light text-white input_box w-100 mb-3"
                  />{" "}
                  {error && contactFormData.company === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                  <textarea
                    value={contactFormData.message}
                    onChange={(event) =>
                      setContactFormData({
                        ...contactFormData,
                        message: event.target.value,
                      })
                    }
                    name="Message"
                    cols="30"
                    rows="4"
                    className="input_box w-100 font_md ff_gilroy_light text-white"
                    placeholder="Message"></textarea>{" "}
                  {error && contactFormData.message === "" ? (
                    <p className="text-danger">This field is required.</p>
                  ) : (
                    ""
                  )}
                </div>
                <button
                  type="submit"
                  className="font_lg mb-5 mb-lg-0 color_blue fw-semibold open_sans send_btn mt-xl-2">
                  Send
                </button>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="text-center text-xxl-end">
                <img
                  className="mb-3 solution_tab_img_max_w "
                  src={map2}
                  alt="SolutionImg"
                />

                <div className="mt-4 d-flex flex-column flex-xl-row justify-content-between text-nowrap">
                  <div className="d-flex align-items-center text-start ms-xxl-5 ps-xxl-5">
                    <img src={phone} alt="phone" />
                    <p className="ms-3 mb-0 text-white font_md ff_gilroy_semibold">
                      PHONE
                      <a
                        href="tel:+44 20 3821 5679"
                        className="d-block text-white text-decoration-none ff_gilroy_normal">
                        +44 20 3821 5679
                      </a>
                    </p>
                  </div>
                  <div className="d-flex align-items-center text-start mt-3 mt-xl-0">
                    <img src={mail} alt="mail" />
                    <p className="ms-3 mb-0 text-white font_md ff_gilroy_semibold">
                      EMAIL
                      <a
                        href="mailto: info@syncvo.com"
                        className="d-block text-white text-decoration-none ff_gilroy_normal">
                        info@syncvo.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
