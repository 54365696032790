import React from "react";

const Enterprise = () => {
  return (
    <>
      <section className="mt-5 pt-lg-5">
        <div className="container mt-4 mt-sm-5 py-5 enterprise_section">
          <div className="d-flex flex-column align-items-center justify-content-center pt-5 pb-4 mb-1 color_black">
            <h2 className="ff_gilroy_medium fw_500 font_xl mb-2">Enterprise</h2>
            <p className="font-sm fw-normal ff_gilroy_light text-center mb-0 pb-3 pb-sm-4">
              Do you have a particular request, or query that you think we may
              be able <br /> to assist you with?
            </p>
            <a
              className="font_lg fw-normal color_white get_touch_btn text-decoration-none my-sm-3"
              href="#contactus"
            >
              Get in touch
            </a>
            <p className="ff_gilroy_light fw-normal mt-3 mb-0">
              Let us personalize your experience
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enterprise;
