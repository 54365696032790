import Participant from "../Solution-Tab/Participant";
import Fieldwork from "../Solution-Tab/Fieldwork";
import Dataservices from "../Solution-Tab/Dataservices";
import solutionImg from "../../assets/images/png/solution_bg_img.png";
import solutionImg3 from "../../assets/images/png/syncvo-bg-3-new.png";
import feature1 from "../../assets/images/svg/feature_tab1.svg";
import feature2 from "../../assets/images/svg/feature_tab2.svg";
import feature3 from "../../assets/images/svg/feature_tab3.svg";
import feature4 from "../../assets/images/svg/feature_tab4.svg";
import feature5 from "../../assets/images/svg/feature_tab5.svg";
import feature6 from "../../assets/images/svg/feature_tab6.svg";

export const TabData = [
  {
    tabHeading: "Participant Recruitment",
    content: <Participant />,
    imgurl: solutionImg,
  },
  {
    tabHeading: "Fieldwork Methodologies",
    content: <Fieldwork />,
    imgurl: solutionImg3,
  },
  {
    tabHeading: "Data Services",
    content: <Dataservices />,
    imgurl: solutionImg3,
  },
];
export const sectorUlData = [
  {
    sectorLiData: "Sector-Specific Native Moderation",
  },
  {
    sectorLiData: "Simultaneous",
  },
  {
    sectorLiData: "Translation/Interpretation",
  },
  {
    sectorLiData: "Screener & DG Creation",
  },
  {
    sectorLiData: "Global Transcription",
  },
  {
    sectorLiData: "Translation/Localisation",
  },
  {
    sectorLiData: "Content Analysis & Presentation",
  },
  {
    sectorLiData: "AE Reporting",
  },
  {
    sectorLiData: "Social Listening",
  },
];
export const featureTabData = [
  {
    svgImg: feature1,
    steamlinep: "Streamline your international quotes",
  },
  {
    svgImg: feature2,
    steamlinep: "Transparent rates",
  },
  {
    svgImg: feature3,
    steamlinep: "Single point of contact for your international project",
  },
  {
    svgImg: feature4,
    steamlinep: "Daily analytical updates",
  },
  {
    svgImg: feature5,
    steamlinep: "In-house data services",
  },
  {
    svgImg: feature6,
    steamlinep: "Single invoice",
  },
];
