import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import Logo from "../assets/images/svg/syncvo_logo.svg";
import HeaderLists from "./HeaderLists";

const Header = () => {
  const location = useLocation();
  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 1199.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    return isDesktop ? children : null;
  };

  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPosition2, setScrollPosition2] = useState(() => false);

  // TO FIND SCROLL Y POSITION
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 600) {
      setScrollPosition2(() => true);
    } else if (position < 600) {
      setScrollPosition2(() => false);
    }
    setScrollPosition(position);
  };
  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section
        className={`position-fixed top-0 z-index-1 d-flex w-100 navbar-wrapper pt-sm-4 ${
          scrollPosition2 ? "" : ""
        }`}
        id="home"
      >
        <div className="navbar-wrapper w-100 position-relative z-5 header_bg pt-2">
          <Desktop>
            <div
              className={`container modified_container rounded-pill d-flex justify-content-between align-items-center p-3 ${
                scrollPosition2 ? "nav_bg border-radius-60" : ""
              }`}
            >
              <div className="d-flex">
                <a href="/" className="cursor_pointer">
                  <img src={Logo} alt="navbar-logo" />
                </a>
              </div>
              <div className="d-flex">
                <div className="nav-list-items d-flex">
                  <ul className="list-unstyled d-flex align-items-center mb-0">
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link main_para open_sans"
                        href="#healthcare"
                      >
                        Healthcare
                      </a>
                    </li>
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link text-nowrap main_para open_sans"
                        href="#solutions"
                      >
                        Solutions
                      </a>
                    </li>
                    <li
                      className={`ms-4 ${
                        location.pathname === "/" ? "" : "d-none"
                      }`}
                    >
                      <a
                        className="text-decoration-none navbar-link text-nowrap main_para open_sans"
                        href="#panel"
                      >
                        Panel
                      </a>
                    </li>
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link main_para open_sans"
                        href="#artificialintelligence"
                      >
                        Artificial Intelligence
                      </a>
                    </li>
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link main_para open_sans"
                        href="#coreteam"
                      >
                        Core-Team
                      </a>
                    </li>
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link main_para open_sans"
                        href="#projectjourney"
                      >
                        Project Journey
                      </a>
                    </li>
                    <li className="ms-4">
                      <a
                        className="text-decoration-none navbar-link main_para open_sans"
                        href="#streamline"
                      >
                        Streamline
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ms-4">
                  <a
                    href="#contactus"
                    className="text-decoration-none common_btn header_btn"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </Desktop>
          <BeforeDesktop>
            <div className="container">
              <div
                className={`d-flex justify-content-between align-items-center py-3 px-4 ${
                  scrollPosition2 ? "nav_bg border-radius-60 px-4 py-3" : ""
                }`}
              >
                <div className="nav-log cursor_pointer z-index_100">
                  <img src={Logo} alt="logo" />
                </div>
                <div
                  id="nav-icon1"
                  className={`hamburger-menu`}
                  onClick={() => handldeOverlayActive()}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <HeaderLists handldeOverlayActive={handldeOverlayActive} />
            </div>
          </BeforeDesktop>
        </div>
      </section>
    </>
  );
};

export default Header;
