import React, { useLayoutEffect, useState } from "react";
import JoinPannelImg from "../assets/images/png/join-our-pannel-img-new.png";
import centerLine from "../assets/images/png/BoxCenterLine.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import emailjs, { init } from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import { gsap } from "gsap";
init("hqIkri0diF2ZF3hS6");
const JoinOurPanel = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let joinourpannel = gsap.timeline({
          scrollTrigger: {
            trigger: ".pannel_sec",
            start: "top 30%",
            end: "center center",
            scrub: 1,
          },
        });
        joinourpannel.fromTo(
          ".panel_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "300px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:576px) and (max-width:991px)": function () {
        let joinourpannel = gsap.timeline({
          scrollTrigger: {
            trigger: ".pannel_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        joinourpannel.fromTo(
          ".panel_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    let joinourpannelanimate = gsap.timeline({
      scrollTrigger: {
        trigger: ".pannel_sec",
        start: "top 70%",
        end: "center bottom",
        scrub: 1,
      },
    });
    joinourpannelanimate
      .fromTo(
        ".panel_text",
        {
          xPercent: -100,
          duration: 1,
        },
        {
          xPercent: 10,
        }
      )
      .fromTo(
        ".pannel_img",
        {
          xPercent: -100,
          duration: 1,
        },
        {
          xPercent: 0,
        }
      );
  }, []);

  const data = {
    name: "",
    email: "",
    number: "",
    speciality: "",
  };

  const [contactFormData, setContactFormData] = useState(data);
  const [error, setError] = useState(false);
  const handleSubmitContactForm = (event) => {
    event.preventDefault();
    setError(true);
    if (
      contactFormData.name !== "" &&
      contactFormData.number !== "" &&
      contactFormData.email !== "" &&
      contactFormData.speciality !== ""
    )
      emailjs
        .send("service_o9no54l", "template_ubmxo18", {
          name: contactFormData.name,
          number: contactFormData.number,
          email: contactFormData.email,
          speciality: contactFormData.speciality,
        })
        .then(() => {
          toast("Thank you for submission!");
          setContactFormData(data);
          setError(false);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="pannel_sec py-md-5 " id="panel">
        <div className="position-relative border_rounded_custom_all_images_Mobile panel_bg my-5 mx-auto">
          <p className="font_2xl fw_500 ff_gilroy_medium text-white position-absolute health_p_pl panel_text">
            Panel
          </p>
        </div>
        <div className="container pt-lg-3 pb-lg-5 mb-lg-5">
          <div className="bg_blue rounded_border position-relative panel_parent">
            <img
              className="position-absolute box_center_line d-none d-lg-block "
              src={centerLine}
              alt="box-center-line"
            />
            <div className="row justify-content-between py-sm-5">
              <div className="col-lg-6">
                <div className="max_w_joinourpannel">
                  <div className="px-4 ps-lg-5 ms-sm-2 ms-md-4 pt-3">
                    <h3 className="font_xl color_white fw-lighter ff_gilroy_light mb-0">
                      Paving a path for future generation.
                    </h3>
                    <p className="main_para ff_gilroy_normal pt-3 pt-lg-4 mb-0">
                      With our in-depth understanding of the medical field, we
                      are able to expand our reach to a various range of
                      targets, including doctors, nurses, patients, and so much
                      more.
                    </p>
                  </div>
                  <div>
                    <div className="pannel_img ">
                      <img
                        className="position-absolute join_our_pannel_img d-none d-lg-block rounded_border"
                        src={JoinPannelImg}
                        alt="join-our-pannel"
                      />
                    </div>
                    <div className="text-center py-3">
                      <img
                        className="d-lg-none w-100 rounded_border participant_img_max_width text-center"
                        src={JoinPannelImg}
                        alt="join-our-pannel"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="pt-4 mb-5 pb-lg-5 px-4 px-md-5 px-lg-2 mt-lg-2">
                  <h3 className="font_xl color_white fw-normal ff_gilroy_normal mb-0">
                    Join our&nbsp;
                    <span className="fw_medium ff_gilroy_medium">Panel</span>
                  </h3>
                  <p className="main_para ff_gilroy_light fw-light m-0 pt-3 pt-lg-4">
                    Join our extensive in-house panel of healthcare
                    professionals, IT specialists, and patients. Share your
                    expertise, gain new perspectives, and earn an extra income.
                  </p>
                  <form
                    className="pt-4 mt-2"
                    onSubmit={(e) => handleSubmitContactForm(e)}>
                    <div>
                      <input
                        type="text"
                        placeholder="Name*"
                        className="input_modified font_sm fw_500 ff_gilroy_medium position-relative"
                        value={contactFormData.name}
                        onChange={(event) =>
                          setContactFormData({
                            ...contactFormData,
                            name: event.target.value,
                          })
                        }
                      />
                      {error && contactFormData.name === "" ? (
                        <p className="text-danger">This field is required.</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="pt-4 pb-2">
                      <input
                        type="email"
                        placeholder="Email*"
                        className="input_modified font_sm fw_500 ff_gilroy_medium position-relative"
                        value={contactFormData.email}
                        onChange={(event) =>
                          setContactFormData({
                            ...contactFormData,
                            email: event.target.value,
                          })
                        }
                      />
                      {error && contactFormData.email === "" ? (
                        <p className="text-danger">This field is required.</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="pt-4 pb-2">
                      <input
                        type="number"
                        placeholder="Phone*"
                        className="input_modified font_sm fw_500 ff_gilroy_medium position-relative"
                        value={contactFormData.number}
                        onChange={(event) =>
                          setContactFormData({
                            ...contactFormData,
                            number: event.target.value,
                          })
                        }
                      />{" "}
                      {error && contactFormData.number === "" ? (
                        <p className="text-danger">This field is required.</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="pt-4 pb-2 pb-lg-3">
                      <input
                        type="text"
                        placeholder="Speciality* "
                        className="input_modified font_sm fw_500 ff_gilroy_medium position-relative"
                        value={contactFormData.speciality}
                        onChange={(event) =>
                          setContactFormData({
                            ...contactFormData,
                            speciality: event.target.value,
                          })
                        }
                      />{" "}
                      {error && contactFormData.speciality === "" ? (
                        <p className="text-danger">This field is required.</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <button className="font_sm color_black fw-semibold open_sans join_btn mt-4">
                      Join Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinOurPanel;
