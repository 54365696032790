import React from "react";
import ScreenFixedProvider from "./components/context/ScreenFixedProvider";

import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
ReactDOM.render(
  <React.StrictMode>
    <ScreenFixedProvider>
      <App />
    </ScreenFixedProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
