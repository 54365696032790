import React, { useLayoutEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import CoreData from "./Core-Team-Tab/CoreData";
import CoreExecutive from "../Core-Team-Tab/CoreExecutive";
import CorePerformance from "../Core-Team-Tab/CorePerformance";
import CoreUtility from "../Core-Team-Tab/CoreUtility";
import BoxCenterLine from "../../assets/images/png/BoxCenterLine.png";
import BoxCenterLineMobile from "../../assets/images/png/BoxCenterLine_mobile_version.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
const IndustryCore = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let industrycoreteamparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".industry_core_team_sec",
            start: "top 50%",
            end: "center center",
            scrub: 1,
          },
        });
        industrycoreteamparent.fromTo(
          ".industry_core_team_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:320px) and (max-width:991px)": function () {
        let industrycoreteamparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".industry_core_team_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        industrycoreteamparent.fromTo(
          ".industry_core_team_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "250px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "150px",
          }
        );
      },
    });
    let industrycoreteamtext = gsap.timeline({
      scrollTrigger: {
        trigger: ".industry_core_team_sec",
        start: "top 50%",
        end: "center bottom",
        scrub: 1,
      },
    });
    industrycoreteamtext.fromTo(
      ".coreteam_text",
      {
        xPercent: -90,
        duration: 1,
      },
      {
        xPercent: "10",
      }
    );
  }, []);
  return (
    <>
      <section className="py-lg-5 industry_core_team_sec" id="coreteam">
        <div className="position-relative industry_core_bg industry_core_team_bg my-5 mx-auto">
          <p
            eventKey="Fieldwork"
            className="fs_2xl fw-light ff_gilroy_light text-white position-absolute health_p_pl lh-1 coreteam_text"
          >
            Core-
          </p>
        </div>
        <div className="container">
          <div className="industry_core_gradient rounded_border py-4 py-lg-5 px-4 px-xl-0 position-relative">
            <img
              className="position-absolute core_team_center_line d-none d-lg-block"
              src={BoxCenterLine}
              alt="box-center-line"
            />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="align-items-cente justify-conte nt-center py-lg-4 position-relative">
                <div className="col-12 col-lg-3 mx-auto mx-0 overflow-auto webkit_scrollbar_core_team">
                  <Nav
                    variant="pills"
                    className="flex-lg-column d-flex flex-nowrap"
                  >
                    <Nav.Item className="pt-4 pt-lg-0 pb-3 pb-lg-0   ">
                      <Nav.Link
                        eventKey="first"
                        className="text-white fs_lg ff_gilroy_light fw-light text-nowrap lh-1"
                      >
                        Core-Executive
                        <span className="font_sm ff_gilroy_light fw-light tab_span_translate d-block pb-1">
                          Team
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="pt-4 ms-3 ms-lg-0 pb-3 pb-lg-0">
                      <Nav.Link
                        eventKey="second"
                        className="text-white fs_lg ff_gilroy_light fw-light text-nowrap lh-1"
                      >
                        Core-Utility
                        <span className="font_sm ff_gilroy_light fw-light tab_span_translate d-block pb-1">
                          Team
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="pt-4 ms-3 ms-lg-0 pb-3 pb-lg-0">
                      <Nav.Link
                        eventKey="third"
                        className="text-white fs_lg ff_gilroy_light fw-light text-nowrap lh-1"
                      >
                        Core-Performance
                        <span className="font_sm ff_gilroy_light fw-light tab_span_translate d-block pb-1">
                          Team
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="mx-auto col-lg-9 col-xl-7 mt-5">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CoreExecutive />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <CoreUtility />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <CorePerformance />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustryCore;
