import React from "react";

const CoreUtility = () => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-center text-center flex-column px-2 px-lg-5 me-lg-3 ms-lg-5">
          <h3 className="fw-light ff_gilroy_light font_xl color_white mb-0">
            Core-Utility
          </h3>
          <p className="fw-light ff_gilroy_light color_white font_sm pt-3 pt-lg-4 mt-lg-1">
            Our Core-Utility team is responsible for all sales and client
            relations. They are the primary point of contact for clients, and
            work to ensure that all needs and requests are met in a timely and
            efficient manner. They are up-to-date on current market trends and
            are always ready to tackle new requests and challenges.
          </p>
        </div>
      </div>
    </>
  );
};

export default CoreUtility;
