import OverviewIndustryImg from "../../../assets/images/png/map2.png";
import BussinessDevlepmentImg from "../../../assets/images/png/bussiness-dev-img.png";
import CostSavingImg from "../../../assets/images/png/cost-saving-img.png";
import ProjectMangmentImg from "../../../assets/images/png/project-mangement-img.png";

const IndustryPricing = () => {
  return (
    <>
      <section className="pt-5 mt-md-4">
        <div className="row justify-content-center justify-content-lg-between align-items-center">
          <div className="col-lg-6">
            <h4 className="ff_gilroy_normal fw-normal fs_35 color_white mb-0">
              For the first time, a service dedicated to you
            </h4>
            <p className="fs_lg ff_gilroy_normal fw-normal mb-0 pt-3 pt-lg-4 color_white">
              Maximising success for our clients internationally. Boosting their
              revenue, enhancing reputation, and saving them resources
            </p>
            <p className="fs_lg ff_gilroy_normal fw-normal mb-0 pt-3 pt-lg-4 color_white">
              In-house fieldwork services for the UK & Ireland. Even better, we
              provide fieldwork services globally
            </p>
          </div>
          <div className="col-sm-10 col-lg-6">
            <div className="d-flex justify-content-end ms-xl-5 pt-4 pt-lg-0">
              <img
                className="w-100"
                src={OverviewIndustryImg}
                alt="Overview-Industry-Img"
              />
            </div>
          </div>
        </div>
        <div className="row pt-4 pt-lg-5 mt-5 position-relative industry_overview_box justify-content-center justify-content-lg-start">
          <div className="col-lg-11">
            <div className="industry_overview">
              <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                Business development
              </p>
              <h3 className="ff_gilroy_light fw-light fs_xl color_white pt-1">
                Expand Your Reach:
              </h3>
              <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 pt-lg-4">
                Go global with confidence.
              </p>
              <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-lg-2 overview_devlepment_max_w">
                Trust our vetted partners for international fieldwork services.
                From recruitment, to various fieldwork methodologies, and full
                data services.
              </p>
              <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-lg-2">
                Recruitment and fieldwork services in UK & Ireland are done by
                us.
              </p>
            </div>
          </div>
          <div className="col-sm-8 d-lg-none pt-4">
            <img
              className="w-100"
              src={BussinessDevlepmentImg}
              alt="bussiness-devlepment"
            />
          </div>
          <div className="col-1 position-absolute bussiness_devlepment_img_positioning d-none d-lg-block">
            <img src={BussinessDevlepmentImg} alt="bussiness-devlepment" />
          </div>
        </div>
        <div className="row mt-4 mt-lg-0 pt-4 pt-lg-5 position-relative industry_overview_box justify-content-center justify-content-lg-start">
          <div className="col-lg-11 pb-lg-4">
            <div className="industry_overview pb-lg-5">
              <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                Cost saving
              </p>
              <h3 className="ff_gilroy_light fw-light fs_xl color_white pt-1">
                Streamline Quotation:
              </h3>
              <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 pt-lg-4 overview_cost_max_w">
                We work with top-quality international partners who offer
                competitive rates. We're transparent and fair by passing on the
                savings to you, quoting the exact rates provided by our
                partners.
              </p>
            </div>
          </div>
          <div className="col-sm-8 d-lg-none pt-4">
            <img
              className="w-100"
              src={CostSavingImg}
              alt="bussiness-devlepment"
            />
          </div>
          <div className="col-1 position-absolute bussiness_devlepment_img_positioning d-none d-lg-block">
            <img src={CostSavingImg} alt="bussiness-devlepment" />
          </div>
        </div>
        <div className="row mt-4 mt-lg-0 pt-4 pt-lg-5 position-relative industry_overview_box justify-content-center justify-content-lg-start">
          <div className="col-lg-11 pb-lg-5">
            <div className="industry_overview pb-lg-5">
              <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                Project Management
              </p>
              <h3 className="ff_gilroy_light fw-light fs_xl color_white pt-1">
                Single Point of Contact:
              </h3>
              <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 pt-lg-4 overview_project_max_w">
                We assign a core-performance manager to you, who will be
                directing your international project, co-ordinating all aspects
                of the tasks involved and providing you with regular daily
                updates.
              </p>
            </div>
          </div>
          <div className="col-sm-8 d-lg-none pt-4">
            <img
              className="w-100"
              src={ProjectMangmentImg}
              alt="bussiness-devlepment"
            />
          </div>
          <div className="col-1 position-absolute bussiness_devlepment_img_positioning d-none d-lg-block">
            <img src={ProjectMangmentImg} alt="bussiness-devlepment" />
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustryPricing;
