import React, { useLayoutEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SolutionImg from "../assets/images/png/solution-tab-home.png";
import { gsap } from "gsap";
const ArtificialIntelligence = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let artificialparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".artifical_sec",
            start: "top 30%",
            end: "center center",
            scrub: 1,
          },
        });
        artificialparent.fromTo(
          ".artifical_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:320px) and (max-width:991px)": function () {
        let artificialparent = gsap.timeline({
          scrollTrigger: {
            trigger: ".artifical_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        artificialparent.fromTo(
          ".artifical_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    let text = gsap.timeline({
      scrollTrigger: {
        trigger: ".artifical_sec",
        start: "top bottom",
        end: "center bottom",
        scrub: 1,
      },
    });
    text.fromTo(
      ".artifical_text",
      {
        xPercent: -80,
        duration: 1,
      },
      {
        xPercent: "10",
      }
    );
  }, []);
  return (
    <>
      <section
        className="py-xl-5 my-lg-5 artifical_sec"
        id="artificialintelligence"
      >
        <div className="position-relative artifical_bg my-5 mx-auto">
          <p
            eventKey="Fieldwork"
            className="font_2xl fw-light ff_gilroy_light text-start text-white position-absolute health_p_pl lh-1 artifical_text"
          >
            Artificial
            <span className="fw_500 ff_gilroy_medium d-block">
              Intelligence
            </span>
          </p>
        </div>
        <div className="container">
          <div className="pt-lg-3 mt-lg-5">
            <div className="bg_blue rounded_border">
              <div className="row align-items-center justify-content-center position-relative">
                <div className="col-lg-6 col-xl-7">
                  <div className="pt-4 mt-2 mt-sm-0 pb-4 pb-sm-5 py-md-5 my-lg-4 ps-xxl-5 mx-4 mx-lg-5 me-lg-0 me-xl-5">
                    <p className="main_para ff_gilroy_normal pt-2">
                      We also specialise in the field of Al innovation within
                      the Healthcare and Pharmaceutical industries. We
                      understand the importance of staying up-to-date with the
                      latest advancements in these fields, and make it our
                      mission to gather valuable insights from key
                      decision-makers and experts in the Al space. We recruit
                      and gather data for our clients from, but not limited to:
                    </p>
                    <ul className="pt-md-4 mt-2 ps-3 ps-lg-4">
                      <li className="main_para ff_gilroy_normal">
                        Start-up companies and entrepreneurs in the Al and
                        Healthcare and Pharmaceutical sectors
                      </li>
                      <li className="main_para ff_gilroy_normal pt-3 pt-lg-4">
                        Investors and venture capitalists interested in the
                        growth and potential of AI in these industries
                      </li>
                      <li className="main_para ff_gilroy_normal pt-3 pt-lg-4">
                        Reasearchers and scientists working on AI-related
                        projects in healthcare and pharamaceutical companies.
                      </li>
                      <li className="main_para ff_gilroy_normal pt-3 pt-lg-4">
                        Medical professionals and healthcare providers utilizing
                        AI technology in their practices
                      </li>
                      <li className="main_para ff_gilroy_normal pt-3 pt-lg-4">
                        Policy-makers and regulators shaping the future of AI in
                        healthcare and pharmaceutical
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-none d-xl-block col-sm-11 col-md-10 col-lg-6 col-xl-5 mx-auto mx-lg-0 p-4 p-sm-0 pb-sm-5 pb-lg-0 pe-lg-5 pe-xl-0">
                  <div className="position-absolute aritficail_side_positioning fieldwork_img">
                    <img
                      className="w-100 aritficail_side_img rounded_border_images"
                      src={SolutionImg}
                      alt="SolutionImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArtificialIntelligence;
