import React from "react";
import featureImg from "../../assets/images/png/stream-line-tab-img-new.png";
import featuretabimg from "../../assets/images/png/feature_tab_new_img.png";
import BoxCenterLine from "../../assets/images/png/BoxCenterLine.png";

const Features = () => {
  return (
    <>
      <div className="container">
        <div className="row pt-sm-5 mt-lg-3">
          <div className="col-12 color_black">
            <div className="features_content d-flex align-items-center mb-lg-4">
              <div className="line012 me-3"></div>
              <p className="ff_gilroy_light font_xl fw-normal lh_142 mb-0">
                The 3 services that you can <br className="d-none d-sm-block" />
                expect from us internationally.
              </p>
            </div>
          </div>
        </div>
        <div className="row features_box_pdig position-relative  bg_blue rounded_border justify-content-between mt-4 mt-lg-5">
          <div className="col-lg-5">
            <div className=" py-lg-3 ">
              <h3 className="font_lg color_white fw-light open_sans mb-0 px-xl-5">
                Recruitment and Fieldwork&nbsp;
                <br className="d-none d-sm-inline-block" />
                <span>services are done in-house for</span>
                <br className="d-none d-sm-inline-block" />
                <span>UK & Ireland</span>
              </h3>
              <div className="mt-4 mt-lg-0">
                <img
                  src={featureImg}
                  alt="featureImg"
                  className="feature_tab_img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ps-sm-3 pt-4 pe-xxl-5">
              <p className="font_30 text-white ff_gilroy_medium fw_500 mb-0">
                Recruitment
              </p>
              <p className="fw-light ff_gilroy_light font_sm text-white ">
                We are able to extend your reach to various Healthcare
                professionals, staff, and patients globally through our vetted
                partners.
              </p>
            </div>
            <div className="ps-sm-3 pe-xxl-5">
              <p className="font_30 text-white ff_gilroy_medium fw_500 mb-0">
                Fieldwork
              </p>
              <p className="fw-light ff_gilroy_light font_sm text-white pe-xl-5">
                Our globally vetted partners provide various fieldwork
                methodologies, from remote to central locations including venue
                management. This includes all scheduling and moderation in local
                languages, if required.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-5">
            <div>
              <p className="font_30 ff_gilroy_medium mt-4 mt-sm-5 pt-4 before_data_line">
                <span className="pt-2 d-block">Data services</span>
              </p>
              <p className="mt-1 ff_gilroy_light pe-5 mb-4 mb-lg-0">
                Creation of all project materials; such as discussion guides,
                screeners, consent forms. We provide full-reports, content
                analysis, transcript, translation, and more.
              </p>
            </div>
          </div>
        </div>
        <div className="row features_box_pdig bg_blue rounded_border mt-5 pt-5 position-relative">
          <div className="col-md-8 col-lg-6">
            <div className="pt-lg-4 ms-xxl-5 text-white z_index_one position-relative ps-lg-5">
              <div className="d-flex align-items-center mb-lg-4">
                <img
                  className="position-absolute all_need_center_line"
                  src={BoxCenterLine}
                  alt="center_line"
                />
                <p className="ff_gilroy_normal font_30 fw-normal lh_142 mb-0 ps-4">
                  All you need, in <br />
                  one place:
                </p>
              </div>
              {/* <h6 className="ff_gilroy_normal font_sm mb-1">
                All you need, in one place:
              </h6> */}
              <ul className="ps-4">
                <li className="ff_gilroy_normal font_sm py-2">
                  Streamline your international quotes
                </li>
                <li className="ff_gilroy_normal font_sm pb-2">
                  Transparent rates
                </li>
                <li className="ff_gilroy_normal font_sm pb-2">
                  Single point of contact for your international project
                </li>
                <li className="ff_gilroy_normal font_sm pb-2">
                  Daily analytical updates
                </li>
                <li className="ff_gilroy_normal font_sm pb-2">Data services</li>
                <li className="ff_gilroy_normal font_sm ">Single invoice</li>
              </ul>

              {/* <div className="mt-sm-5 pt-4 pb-4 pb-xl-0 pt-xl-5">
                <ul className="streamline_capsule_text mb-4 mt-5">
                  <li className="ff_gilroy_normal font_sm text-black py-2">
                    Streamline your international quotes
                  </li>
                </ul>
                <ul className="streamline_capsule_text">
                  <li className="ff_gilroy_normal font_sm text-black py-2">
                    Streamline your international quotes
                  </li>
                </ul>
              </div> */}

              {/* {featureTabData.map((obj, index) => {
                return (
                  <div
                    className={`pt-2 d-flex align-items-center ${
                      index === 1 ? "pb-5" : index >= 2 ? "my-border mb-2" : ""
                    }`}
                    key={index}
                  >
                    <img
                      src={obj.svgImg}
                      alt="feature1"
                      className="z_index_one"
                    />
                    <p className="streamline_box ff_gilroy_normal font_sm mb-0 w-100">
                      {obj.steamlinep}
                    </p>
                  </div>
                );
              })} */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-center pt-lg-4">
              <img
                src={featuretabimg}
                alt="featuretabimg"
                className="all_need_img z_index_one pe-lg-4 pe-xl-5"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
