import React, { useLayoutEffect, useState } from "react";
// import SolutionImg from "../../assets/images/png/solution-tab-img-new.png";
import SolutionImg from "../../assets/images/png/feature_tab_img.png";
import SolutionImg2 from "../../assets/images/png/feature_tab.png";
import { Tab, Tabs } from "react-bootstrap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

const Fieldwork = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    let second = gsap.timeline({
      scrollTrigger: {
        trigger: ".fieldwork_sec",
        start: "top 50%",
        end: "center center",
        scrub: 1,
      },
    });
    second.fromTo(
      ".fieldwork_img",
      {
        top: "-5%",
      },
      {
        top: "30%",
      }
    );
  }, []);
  const [FieldKey, setFieldKey] = useState("home");
  return (
    <>
      <section className="pt-4 pt-xl-5 mt-1 my-lg-5 py-sm-4 fieldwork_sec">
        <div className="container">
          <Tabs
            id="controlled-tab-example"
            activeKey={FieldKey}
            onSelect={(k) => setFieldKey(k)}
            className="tabs_left_padding">
            <Tab id="fieldwork_btn" eventKey="home" title="Qualitative">
              <div className="row position-relative">
                <div className="col-lg-9 col-xl-10 m-auto m-lg-0 bg_blue rounded_border p-0">
                  <div className="bg_blue rounded_border">
                    <div className="max_w_fieldwork ps-lg-4 ms-lg-3 px-2 mt-2 my-sm-2 pt-4 pb-4 pb-sm-5 py-md-5 my-lg-4">
                      <ul className="mb-0">
                        <li className="fw-light ff_gilroy_light font_sm color_white">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            In-depth interviews
                          </span>
                          - one-on-one interviews with a single respondent,
                          allows for in-depth exploration of a specific topic or
                          individual's experiences and perspectives.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            Focus groups
                          </span>
                          - small group discussions facilitated by a moderator,
                          allows for group dynamics and collective perspectives
                          to be explored.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            Ethnography
                          </span>
                          - qualitative research method that involves spending
                          time in a particular community or culture to observe
                          and understand the social behaviors and customs of the
                          group.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            Unstructured interviews
                          </span>
                          - interviews without a pre-determined set of
                          questions, allowing for flexibility and spontaneity in
                          the conversation.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            Diaries, journals,
                          </span>
                          and other forms of self-recorded data -
                          self-reflection and documentation by the respondent,
                          allowing for a personal perspective
                        </li>
                      </ul>
                    </div>
                    <div className="text-center pb-4 pb-sm-5 pb-lg-0 px-3 px-sm-0">
                      <img
                        className="w-100 rounded_border_images d-lg-none participant_img_max_width"
                        src={SolutionImg}
                        alt="SolutionImg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 d-none d-lg-block">
                  <div className="position-absolute solution_tab_positioning fieldwork_img">
                    <img
                      className="w-100 solution_tab_img_max_w rounded_border_images"
                      src={SolutionImg}
                      alt="SolutionImg"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Quantitative " title="Quantitative">
              <div className="row position-relative">
                <div className="col-lg-9 col-xl-10 m-auto m-lg-0 bg_blue rounded_border px-0">
                  <div className="bg_blue rounded_border">
                    <div className="max_w_fieldwork ps-lg-4 ms-lg-3 px-2 mt-2 my-sm-2 pt-4 pb-4 pb-sm-5 py-md-5 my-lg-4">
                      <ul>
                        <li className="fw-light ff_gilroy_light font_sm color_white">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            Online Surveys:
                          </span>
                          Participants complete the survey via the internet, can
                          be self-administered or administered by a researcher.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            CATI (Computer Assisted Telephone Interviews):
                          </span>
                          Interviews conducted over the phone with the help of a
                          computer software.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            CAWI (Computer Assisted Web Interviews):
                          </span>
                          Interviews conducted over the internet, similar to
                          online surveys but conducted by a researcher.
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            CAPI (Computer Assisted Personal Interviews):
                          </span>
                          Interviews conducted in-person with the help of a
                          computer software. PAPI
                        </li>
                        <li className="fw-light ff_gilroy_light font_sm color_white pt-3">
                          <span className="fw_500 ff_gilroy_medium font_sm color_white">
                            (Paper and Pencil Interviews):
                          </span>
                          Interviews conducted in-person or by mail,
                          participants fill out a paper survey.
                        </li>
                      </ul>
                    </div>
                    <div className="text-center pb-4 pb-sm-5 pb-lg-0 px-3 px-sm-0">
                      <img
                        className="w-100 rounded_border_images participant_img_max_width d-lg-none"
                        src={SolutionImg2}
                        alt="SolutionImg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 d-none d-lg-block">
                  <div className="position-absolute solution_tab_positioning fieldwork_img">
                    <img
                      className="w-100 solution_tab_img_max_w rounded_border_images"
                      src={SolutionImg2}
                      alt="SolutionImg"
                    />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Fieldwork;
