import React, { useLayoutEffect } from "react";
import EthnographyImg from "../../../assets/images/svg/Ethnography-img.svg";
import ShoppingImg from "../../../assets/images/svg/shopping.svg";
import DepthInterviewImg from "../../../assets/images/svg/in-depth-interview.svg";
import EthnographyImg2 from "../../../assets/images/svg/Ethnography-img-2.svg";
import OnlineSurveyImg from "../../../assets/images/svg/online-surveys.svg";
import ObservationImg from "../../../assets/images/svg/observation-img.svg";
import { gsap } from "gsap";

const IndustryRequirment = () => {
  useLayoutEffect(() => {
    let industryrequirment = gsap.timeline({
      scrollTrigger: {
        trigger: ".industry_requirment_parent",
        start: "top 100%",
        end: "bottom center",
      },
    });
    industryrequirment
      .from(".industry_requirment_text", {
        xPercent: -100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(1)",
      })
      .from(".industry_requirment_para", {
        xPercent: 100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(1)",
      })
      .from(".industry_requirment_text2", {
        xPercent: -100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(1)",
      })
      .from(
        ".industry_requirment_para2",
        {
          xPercent: 100,
          autoAlpha: 0,
          duration: 1,
          ease: "back(1)",
        },
        "=-.6"
      )

      .from(".industry_requirment_text3", {
        xPercent: -100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(1)",
      })
      .from(
        ".industry_requirment_para3",
        {
          xPercent: 100,
          autoAlpha: 0,
          duration: 1,
          ease: "back(1)",
        },
        "=-.6"
      );
  }, []);
  return (
    <>
      <section className="pt-5 mt-lg-5 industry_requirment_parent">
        <div className="row position-relative justify-content-center justify-content-between">
          <div className="col-xl-6">
            <div className="industry_requirment_text">
              <div className="row position-relative industry_requirment_box">
                <div className="col-md-11 pb-md-4">
                  <div className="industry_requirment pb-md-5">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      Ethnography
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 ethnography_max_w">
                      Observing people in their natural environments to
                      understand behaviors, attitudes, and motivations.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={EthnographyImg}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 position-absolute requirment_img_positioning d-none d-md-block">
                  <img
                    className="solution_images_shadow"
                    src={EthnographyImg}
                    alt="bussiness-devlepment"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 pt-5 pt-xl-0">
            <div className="industry_requirment_para">
              <div className="row position-relative ms-xl-4 industry_requirment_box">
                <div className="col-md-11">
                  <div className="industry_requirment pb-md-5">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      Mystery shopping
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 mystery_max_w">
                      Sending evaluators to interact with a company's
                      products/services as a regular customer to evaluate
                      experience.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={ShoppingImg}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 solution_images_shadow position-absolute requirment_img_positioning d-none d-md-block">
                  <img src={ShoppingImg} alt="bussiness-devlepment" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5 mt-xl-4 position-relative justify-content-center justify-content-between">
          <div className="col-xl-6">
            <div className="industry_requirment_text2">
              <div className="row position-relative industry_requirment_box">
                <div className="col-md-11">
                  <div className="industry_requirment">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      In-depth interviews
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 ethnography_max_w">
                      Observing pOne-on-one conversations to gain deep
                      understanding of perspectives, experiences, and attitudes.
                      eople in their natural environments to understand
                      behaviors, attitudes, and motivations.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={DepthInterviewImg}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 solution_images_shadow position-absolute requirment_img_positioning d-none d-md-block">
                  <img src={DepthInterviewImg} alt="bussiness-devlepment" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 pt-5 pt-xl-0">
            <div className="industry_requirment_para2">
              <div className="row position-relative ms-xl-4 industry_requirment_box">
                <div className="col-md-11 pb-lg-3">
                  <div className="industry_requirment pb-md-5">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      Ethnography
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 mystery_max_w">
                      Observing people in their natural environments to
                      understand behaviors, attitudes, and motivations.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={EthnographyImg2}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 solution_images_shadow position-absolute requirment_img_positioning d-none d-md-block">
                  <img src={EthnographyImg2} alt="bussiness-devlepment" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5 mt-xl-4 position-relative justify-content-center justify-content-between">
          <div className="col-xl-6">
            {" "}
            <div className="industry_requirment_text3">
              <div className="row position-relative industry_requirment_box">
                <div className="col-md-11 pb-md-4">
                  <div className="industry_requirment pb-md-5">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      Online surveys
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 ethnography_max_w">
                      Quick and efficient method of collecting quantitative
                      data, used in many industries, particularly finance and
                      retail.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={OnlineSurveyImg}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 solution_images_shadow position-absolute requirment_img_positioning d-none d-md-block">
                  <img src={OnlineSurveyImg} alt="bussiness-devlepment" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 pt-5 pt-xl-0">
            <div className="industry_requirment_para3">
              <div className="row position-relative ms-xl-4 industry_requirment_box">
                <div className="col-md-11">
                  <div className="industry_requirment pb-md-5">
                    <p className="ff_gilroy_light fw-light fs_md color_white mb-0">
                      Observation
                    </p>
                    <p className="ff_gilroy_light fw-light fs_sm color_white mb-0 pt-3 mystery_max_w">
                      behaviors and interactions in a setting to gather data.
                      Commonly used in psychology and anthropology to study
                      human behavior.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 d-md-none pt-4">
                  <img
                    className="w-100 solution_images_shadow"
                    src={ObservationImg}
                    alt="bussiness-devlepment"
                  />
                </div>
                <div className="col-1 solution_images_shadow position-absolute requirment_img_positioning d-none d-md-block">
                  <img src={ObservationImg} alt="bussiness-devlepment" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustryRequirment;
