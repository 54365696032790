import React, { useLayoutEffect } from "react";
import Header from "./Header";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
const Hero = () => {
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    let second = gsap.timeline({});
    second
      .fromTo(
        ".hero_heading_text",
        {
          autoAlpha: 0,
          marginTop: "-150px",
        },
        {
          autoAlpha: 1,
          marginTop: "0px",
          duration: 1,
        }
      )
      .fromTo(
        ".hero_para",
        {
          autoAlpha: 0,
          marginTop: "-50px",
        },
        {
          autoAlpha: 1,
          marginTop: "0px",
        }
      );
  }, []);
  return (
    <>
      <div className="hero_bg_img">
        <Header />
        <div className="hero_sec">
          <div className="container modified_container ">
            <div className="row h-100">
              <div className="col-lg-4 col-xl-5"></div>
              <div className="col-lg-8  col-xl-7 pt-5 mt-4 mt-md-5">
                <div className="ps-lg-3 mt-5 pt-5">
                  <h2 className="font_xl fw_800 hero_heading_text ff_gilroy_extrabold color_white mb-0 mb-xl-3 ps-xl-4 ms-xl-5 pb-lg-4 me_xsm_0">
                    Healthcare, Pharmaceutical & AI innovation
                  </h2>
                  <p className="font_lg fw-fw-normal ff_gilroy_normal hero_para hero_para_width color_white  pt-4 pt-xl-5 ms-xl-4 ps-xl-5 ">
                    Focus on participant recruitment, fieldwork & data services
                    for
                    <span className="fw_500 ff_gilroy_medium ms-1">
                      Market Research companies
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
