import React, { useLayoutEffect } from "react";
import project1 from "../../assets/images/svg/project1.svg";
import project2 from "../../assets/images/svg/project2.svg";
import project3 from "../../assets/images/svg/project3.svg";
import project4 from "../../assets/images/svg/project4.svg";
import project5 from "../../assets/images/svg/project5.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

const IndustryProjectJourney = () => {
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:1200px)": function () {
        let industryprojectjourney = gsap.timeline({
          scrollTrigger: {
            trigger: ".industry_Project_bg_animate",
            start: "top 50%",
            end: "center center",
            scrub: 1,
          },
        });
        industryprojectjourney.fromTo(
          ".industry_Project_bg_animate",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "250px",
          }
        );
        let coreteamtext = gsap.timeline({
          scrollTrigger: {
            trigger: ".industry_project_parent",
            start: "top 50%",
            end: "center bottom",
            scrub: 1,
          },
        });
        coreteamtext
          .fromTo(
            ".industry_project_text",
            {
              xPercent: -100,
              duration: 1,
            },
            {
              xPercent: 10,
            }
          )
          .fromTo(
            ".industry_panel_img_1",
            {
              scale: 0,
              stagger: 0.3,
            },
            {
              scale: 1,
            }
          );
      },
    });
  }, []);
  return (
    <>
      <div className="industry_project_parent">
        {" "}
        <div className="py-md-3 my-5 project_sec" id="projectjourney">
          <div className="position-relative industry_Project_bg_animate industry_project_bg mb-5 mx-auto">
            <p className="fs_2xl fw-light ff_gilroy_light text-white position-absolute health_p_pl industry_project_text">
              Project Journey
            </p>
          </div>
          <div className="container position-relative panel_parent mb_100">
            <div className="row justify-content-between project_h bg_project_journey rounded_border px-4 mx-1 mx-xl-0 padding_top_bottom_project_journey">
              <div className="col-md-10 col-xl-2 p-0">
                <div className="mb-5">
                  <div className="text-xl-center mb-4">
                    <img
                      className="industry_panel_img_1"
                      src={project1}
                      alt="project"
                    />
                  </div>
                  <p className="text-white font_lg fw-light ff_gilroy_light whitespace_nowrap">
                    Initial&nbsp;
                    <span className="fw_500 ff_gilroy_medium">
                      Consultation
                    </span>
                  </p>
                  <p className="text-white opacity-75">
                    Our process begins by assessing your project goals, target
                    audience, specific requirements, and budget to customize our
                    services to you. Our expert utility team, up-to-date on
                    industry trends, will provide a strategic quote.
                  </p>
                </div>
              </div>
              <div className="col-md-10 col-xl-2 p-0">
                <div className="h-100 pb-5 mb-xl-0 pb-xl-0">
                  <div className="text-xl-center mb-4">
                    <img
                      className="industry_panel_img_1"
                      src={project2}
                      alt="project"
                    />
                  </div>
                  <p className="text-white font_lg fw-light ff_gilroy_light">
                    <span className="whitespace_nowrap">
                      Set-up and Materials
                    </span>
                    <span className="fw_500 ff_gilroy_medium"> Evaluation</span>
                  </p>
                  <p className="text-white opacity-75">
                    Before starting recruitment, we meticulously evaluate the
                    project overview and inspect all materials, including
                    screening questions, to avoid any hurdles. We also test
                    survey links to guarantee a seamless experience for our
                    respondents.
                  </p>
                </div>
              </div>
              <div className="col-md-10 col-xl-2 p-0">
                <div className="requirment_height d-flex flex-column justify-content-center pb-4 mb-xl-0 pb-xl-0">
                  <div className="text-xl-center mb-4">
                    <img
                      className="industry_panel_img_1 pt-3 pt-sm-0"
                      src={project3}
                      alt="project"
                    />
                  </div>
                  <p className="text-white font_lg fw_500 ff_gilroy_medium">
                    Recruit
                    <span className="fw-light ff_gilroy_light">ment</span>
                  </p>
                  <p className="text-white opacity-75 mb-0">
                    In our recruitment phase, we find the ideal candidates and
                    keep you informed with daily analytics on recruitment
                    progress, enabling you to deliver insights to your clients
                    with greater ease.
                  </p>
                </div>
              </div>
              <div className="col-md-10 col-xl-2 p-0">
                <div className="field_work_height d-flex flex-column justify-content-end mb-5 pb-4 mb-xl-0 pb-xl-0">
                  <div className="text-xl-center mb-4 ">
                    <img
                      className="industry_panel_img_1"
                      src={project4}
                      alt="project"
                    />
                  </div>
                  <p className="text-white font_lg fw-light ff_gilroy_light">
                    <span className="fw_500 ff_gilroy_medium">Field</span>work
                  </p>
                  <p className="text-white opacity-75 mb-0">
                    We provide personalized fieldwork services, ranging from
                    home interviews and surveys to focus groups and in-depth
                    interviews, conducted either remotely or in a central
                    location. Our team strives to exceed your expectations and
                    deliver insights that matter.
                  </p>
                </div>
              </div>
              <div className="col-md-10 col-xl-2 p-0">
                <div className="project_closer_height d-flex flex-column justify-content-end">
                  <div className="text-xl-center mb-2 ">
                    <img
                      className="industry_panel_img_1"
                      src={project5}
                      alt="project"
                    />
                  </div>
                  <p className="text-white font_lg fw-light ff_gilroy_light">
                    Project
                    <span className="fw_500 ff_gilroy_medium"> Closure</span>
                  </p>
                  <p className="text-white opacity-75 mb-0">
                    At our company, deadlines are a top priority and we aim to
                    surpass expectations. Our rolling delivery of data,
                    including transcripts and analysis, guarantees a timely
                    finish. We view the deadline as an opportunity to thank our
                    clients for their collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default IndustryProjectJourney;
