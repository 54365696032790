import React from "react";
import { useLocation } from "react-router-dom";

const HeaderLists = React.memo(({ handldeOverlayActive }) => {
  const location = useLocation();
  return (
    <>
      <div className={`navbar-overlay`}>
        <ul className="pt-4 pt-sm-5 animation_scroll list-unstyled d-flex flex-column align-items-start ps-4 pe-3 ps-sm-5 justify-content-center m-0">
          <li onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#healthcare"
            >
              Healthcare
            </a>
          </li>
          <li className="mt-4 mt-lg-3" onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#solutions"
            >
              Solutions
            </a>
          </li>
          <li
            className={`mt-4 mt-lg-3 ${
              location.pathname === "/" ? "" : "d-none"
            }`}
            onClick={() => handldeOverlayActive()}
          >
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#panel"
            >
              Panel
            </a>
          </li>
          <li className="mt-4 mt-lg-3" onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#artificialintelligence"
            >
              Artificial Intelligence
            </a>
          </li>
          <li className="mt-4 mt-lg-3" onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#coreteam"
            >
              Core-Team
            </a>
          </li>
          <li className="mt-4 mt-lg-3" onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#projectjourney"
            >
              Project Journey
            </a>
          </li>
          <li className="mt-4 mt-lg-3" onClick={() => handldeOverlayActive()}>
            <a
              className="text-decoration-none main_para font_lg open_sans"
              href="#streamline"
            >
              Streamline
            </a>
          </li>
          <li className="mt-1 mt-sm-3 mt-4">
            {/* <button className="common_btn font_lg">Contact us</button> */}
            <a
              href="#contactus"
              className="text-decoration-none common_btn font_lg text-nowrap"
            >
              Contact us
            </a>
          </li>
        </ul>
      </div>
    </>
  );
});

export default HeaderLists;
