import React, { useLayoutEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SolutionImg from "../../assets/images/png/solution-img.png";
import SolutionImg2 from "../../assets/images/png/solution-img2.png";
import SolutionImg3 from "../../assets/images/png/solution-img3.png";
import SolutionImg4 from "../../assets/images/png/solution-img4.png";
import SolutionImg5 from "../../assets/images/png/solution-img5.png";
import SolutionImg6 from "../../assets/images/png/solution-img6.png";
import IndustryRequirment from "./Industry-Solution-Tab/IndustryRequirment";
import IndustryFieldwork from "./Industry-Solution-Tab/IndustryFieldwork";
import IndustryDataService from "./Industry-Solution-Tab/IndustryDataService";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const IndustrySolution = () => {
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let industrysolution = gsap.timeline({
          scrollTrigger: {
            trigger: ".industry_solution_parent",
            start: "top top",
            end: "bottom center",
          },
        });
        industrysolution
          .from(".industry_solution_text", {
            xPercent: -100,
            autoAlpha: 0,
            duration: 1,
            ease: "back(1)",
          })
          .from(
            ".industry_solution_para",
            {
              xPercent: 100,
              autoAlpha: 0,
              duration: 1,
              ease: "back(1)",
            },
            "=-.6"
          );
      },
    });
  }, []);
  const [streamkey, setStreamKey] = useState("home");
  return (
    <>
      <section
        className="pb-lg-1 pt-5 my-xl-3 industry_solution_parent"
        id="solutions"
      >
        <div className="container pt-md-5 pt-lg-0">
          <div className="rounded_border streamline_bg_gradient">
            <div className="industries_solution_box">
              <div className="row mx-auto">
                <div className="col-2 p-0">
                  <div className="overflow-hidden rounded_border_solution">
                    <img
                      className="w-100 scale_animation"
                      src={SolutionImg}
                      alt="solution-img"
                    />
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="rounded_border_solution overflow-hidden transfrom_translate_X_solution_img2">
                    <img
                      className="w-100 scale_animation"
                      src={SolutionImg2}
                      alt="solution-img"
                    />
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="overflow-hidden rounded_border_solution transfrom_translate_X_solution_img3">
                    <img
                      className="w-100 scale_animation"
                      src={SolutionImg3}
                      alt="solution-img"
                    />
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="rounded_border_solution overflow-hidden transfrom_translate_X_solution_img4">
                    <img
                      className="w-100  scale_animation"
                      src={SolutionImg4}
                      alt="solution-img"
                    />
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="rounded_border_solution overflow-hidden transfrom_translate_X_solution_img5">
                    <img
                      className="w-100 scale_animation"
                      src={SolutionImg5}
                      alt="solution-img"
                    />
                  </div>
                </div>
                <div className="col-2 p-0">
                  <div className="transfrom_translate_X_solution_img6 rounded_border_solution overflow-hidden">
                    <img
                      className="w-100 scale_animation"
                      src={SolutionImg6}
                      alt="solution-img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row flex justify-content-between align-items-center pt-5 mt-lg-5 pb-lg-4">
                <div className="industry_solution_text">
                  <h2 className="fs_2xl fw-light ff_gilroy_light color_white mb-0">
                    Solutions
                  </h2>
                </div>
                <div className="industry_solution_para">
                  <p className="fs_xl fw-light ff_gilroy_light color_white mb-0">
                    The desire for perfection{" "}
                  </p>
                </div>
              </div>
              <div className="position-relative indutry_solution_bg_img my-5 mx-auto rounded_border w-100"></div>
              <div className="row pt-xl-4 mb-lg-5 m-auto">
                <div className="col-sm-12 mx-auto">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={streamkey}
                    onSelect={(k) => setStreamKey(k)}
                    className="text-nowrap industry_solution_tabs industry_solution_btn justify-content-center justify-content-sm-between align-items-center"
                  >
                    <Tab eventKey="home" title="Recruitment">
                      <IndustryRequirment />
                    </Tab>
                    <Tab eventKey="Fieldwork" title="Fieldwork">
                      <IndustryFieldwork />
                    </Tab>
                    <Tab eventKey="Data" title="Data services">
                      <IndustryDataService />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className=" stream_line_bg_s ec">
              <div className="container"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustrySolution;
