import React, { useLayoutEffect } from "react";
import StreamLineImg from "../../assets/images/png/stream-line-tab-img.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

const Overview = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    let overviewparent = gsap.timeline({
      scrollTrigger: {
        trigger: ".overview_sec",
        start: "top 50%",
        end: "center center",
        scrub: 1,
      },
    });
    overviewparent.fromTo(
      ".overview_img",
      {
        top: "-5%",
      },
      {
        top: "20%",
      }
    );
  }, []);
  return (
    <>
      <div className="pb-4 pb-md-5 pt-4 pt-md-4 mt-md-3 overview_sec">
        <div className="row position-relative pb-lg-5">
          <div className="col-lg-12 m-auto m-lg-0">
            <div className="bg_blue rounded_border py-4 py-sm-3 px-4">
              <div className="max_w_participant ps-lg-5 pt-lg-4 pb-4 pt-md-5 pb-lg-5 my-xl-4">
                <h3 className="font_35 color_white fw-light open_sans mb-0">
                  For the first time, a service
                  <span className="ff_gilroy_medium"> dedicated to you</span>
                </h3>
                <p className="text-white font_md ff_gilroy_normal pt-3 pt-lg-4 mt-lg-1 ">
                  Maximising success for our clients internationally.{" "}
                  <br className="d-xl-block" /> Boosting their revenue,
                  enhancing reputation, and <br className="d-xl-block" /> saving
                  them resources.
                </p>
                <p className="text-white font_md ff_gilroy_normal mb-0  ">
                  In-house fieldwork services for the UK & Ireland. Even{" "}
                  <br className="d-xl-block" /> better, we provide fieldwork
                  services globally.
                </p>
              </div>
              <div className="text-center pb-sm-4 pb-lg-0 overview_img">
                <img
                  className="w-100 rounded_border_images d-lg-none participant_img_max_width"
                  src={StreamLineImg}
                  alt="StreamlineImg"
                />
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <div className="position-absolute stream_line_tab_positioning overview_img">
              <img
                className="w-100 solution_ta b_img_max_w rounded_border_images"
                src={StreamLineImg}
                alt="StreamLineImg"
              />
            </div>
          </div>
        </div>
        <div className="row pt-5 pt-md-5 mt-lg-5">
          <div className="col-sm-12 ps-sm-4 px-lg-5 ms-lg-3 position-relative pt-xxl-3">
            <div className="d-flex align-items-baseline mt-md-4">
              <h2 className="font_2xl ff_gilroy_medium me-sm-4">01</h2>
              <div className="ms-2 streamline_after_line1 position-relative overview_text">
                <p className="font_md fw-normal ff_gilroy_normal color_light_blue m-0 pt-1">
                  Business development
                </p>
                <h3 className="font_xl color_black fw-normal ff_gilroy_normal mb-0">
                  Expanding Your Reach:
                </h3>
                <p className="main_para color_black ff_gilroy_normal fw-normal pt-3 pt-lg-4 ">
                  Go Global with Confidence. <br /> Trust our vetted partners
                  for international fieldwork services. From recruitment, to
                  various fieldwork methodologies, and full data services.
                  Recruitment and fieldwork services in UK & Ireland are done by
                  us.
                </p>
              </div>{" "}
            </div>
            <div className=" ms-auto pt-4  d-flex align-items-baseline">
              <h2 className="font_2xl ff_gilroy_medium me-sm-4">02</h2>
              <div className="ms-2 streamline_after_line2 position-relative overview_text_2">
                <p className="font_md fw-normal ff_gilroy_normal color_light_blue m-0 pt-1">
                  Cost saving
                </p>
                <h3 className="font_xl color_black fw-normal ff_gilroy_normal mb-0">
                  Streamline Quotation:
                </h3>
                <p className="main_para color_black ff_gilroy_normal fw-normal pt-3 pt-lg-4">
                  We work with top-quality international partners who offer
                  competitive rates.
                  <br className="d-none d-lg-block" /> We're transparent and
                  fair by passing on the savings to you, quoting the exact rates
                  provided <br className="d-none d-lg-block" /> by our partners.
                </p>
              </div>
            </div>
            <div className="pt-4 d-flex align-items-baseline">
              <h2 className="font_2xl ff_gilroy_medium me-sm-4">03</h2>
              <span className="position-relative ">
                <div className="ms-2 streamline_after_line3 overview_text_3">
                  <p className="font_md fw-normal ff_gilroy_normal color_light_blue m-0 pt-1">
                    Project Management
                  </p>
                  <h3 className="font_xl color_black fw-normal ff_gilroy_normal mb-0">
                    Single Point of Contact:
                  </h3>
                  <p className="main_para color_black ff_gilroy_normal fw-normal pt-3 pt-lg-4 ">
                    We assign a core-performance manager to you, who will be
                    directing your international project,
                    <br className="d-none d-lg-block" /> co-ordinating all
                    aspects of the tasks involved and providing you with regular
                    daily updates.
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
