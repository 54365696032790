import React, { useLayoutEffect } from "react";
import Header from "../Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const Hero = () => {
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let hero = gsap.timeline({
          scrollTrigger: {
            trigger: ".hero_parent",
            start: "top 100%",
            end: "bottom center",
            scrub: 3,
            markers: false,
          },
        });
        hero.fromTo(
          ".hero_cards",
          {
            yPercent: 100,
          },
          {
            yPercent: 10,
          },
          "=-.6"
        );
      },
    });
  }, []);
  return (
    <>
      <div className="industry_hero_bg_img hero_parent">
        <Header />
        <div className="hero_sec">
          <div className="container pt-lg-5">
            <div className="row justify-content-between pt-5 px-4 px-md-0">
              <div className="col-md-8 col-lg-7 col-xl-6">
                <div className="pt-5 mt-md-5 my-lg-5 py-lg-5">
                  <h1 className="fs_3xl pt-lg-2 fw-light hero_heading_text ff_gilroy_light color_white mb-0 mb-xl-3">
                    All other industry Insights
                  </h1>
                </div>
              </div>
              <div className="col-sm-9 col-lg-5 ps-lg-4">
                <div className="pb-4 pb-md-5 mb-md-4 my-lg-5 py-lg-5 ms-lg-1">
                  <p className="fs_lg fw-light ff_gilroy_light color_white pt-3 pt-md-4 mt-lg-3 mb-0 px-2 px-md-0">
                    Focus on participant recruitment, fieldwork & data services
                    for Market Research companies
                  </p>
                </div>
              </div>
            </div>
            <div className="modified_container mx-auto px-4 px-md-0">
              <div className="row justify-content-between">
                <div className="col-md-6 col-lg-5">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light box_scale_hover color_white pt-3 pt-md-4 mb-0 industry_hero_drop_bg">
                      <span className="fw_800 ff_gilroy_extrabold">APAC</span>{" "}
                      (Asia-Pacific): Countries in the Asia-Pacific region.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 pt-3 pt-md-0">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light color_white mb-0 industry_hero_drop_bg industry_hero_drop_latam_padding box_scale_hover">
                      <span className="fw_800 ff_gilroy_extrabold">LATAM</span>{" "}
                      (Latin America): Countries in Latin America.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-6 col-lg-5">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light color_white mb-0 industry_hero_drop_bg mt-3 industry_hero_drop_emea_padding box_scale_hover">
                      <span className="fw_800 ff_gilroy_extrabold">EMEA</span>{" "}
                      (Europe, Middle East, and Africa): Countries in Europe,
                      the Middle East, and Africa.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light color_white mb-0 industry_hero_drop_bg mt-3 mt-md-2 industry_hero_drop_oceania_padding box_scale_hover">
                      <span className="fw_800 ff_gilroy_extrabold">
                        Oceania:
                      </span>{" "}
                      Countries in the Pacific Ocean.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-md-6 col-lg-5">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light color_white mb-0 industry_hero_drop_bg mt-3  mt-md-4 industry_hero_drop_america_padding box_scale_hover">
                      <span className="fw_800 ff_gilroy_extrabold">
                        North America
                      </span>
                      : The United States, Canada, and Mexico.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 pt-3 pt-md-0">
                  <div className="hero_cards">
                    <p className="fs_lg fw-light ff_gilroy_light color_white mb-0 industry_hero_drop_bg industry_hero_drop_carribean_padding box_scale_hover">
                      <span className="fw_800 ff_gilroy_extrabold">
                        {" "}
                        Caribbean:
                      </span>{" "}
                      Countries and territories in the Caribbean Sea.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
