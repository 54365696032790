import Hero from "../components/Hero";
import Streamline from "../components/Streamline";
import Pharmaceutical from "../components/Pharmaceutical";
import Solutions from "../components/Solutions";
import JoinOurPanel from "../components/JoinOurPanel";
import ArtificialIntelligence from "../components/ArtificialIntelligence";
import ProjectJourney from "../components/ProjectJourney";
import Footer from "../components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CoreTeam from "../components/CoreTeam";
import StreamlineTabs from "../components/StreamlineTabs";
import BackToTop from "../components/common/BackToTop";
import Contact from "./Contact";
// import ContactForm from "../components/ContactForm";

function home() {
  return (
    <>
      <Hero />
      <Streamline />
      <Pharmaceutical />
      <Solutions />
      <JoinOurPanel />
      <ArtificialIntelligence />
      <CoreTeam />
      <ProjectJourney />
      <StreamlineTabs />
      <Contact />
      <Footer />
      <BackToTop />
    </>
  );
}

export default home;
