import React from "react";
import logo from "../assets/images/png/logo.png";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <>
      <section className="bg_blue mt-5">
        <div className="container px-xl-5 py-4">
          <div className="d-flex justify-content-between gap-4 mx-xl-4">
            <a href="/">
              <img src={logo} alt="footer-logo" className="footer_logo" />
            </a>
            <a
              className="icon_hover"
              href="https://www.linkedin.com/company/syncvo"
            >
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.4417 0.584165H2.56086C2.3149 0.580749 2.07067 0.625818 1.84213 0.716798C1.61359 0.807778 1.40521 0.942887 1.22889 1.11441C1.05257 1.28593 0.911765 1.49051 0.814516 1.71645C0.717267 1.9424 0.665479 2.18529 0.662109 2.43125V24.5704C0.665479 24.8164 0.717267 25.0593 0.814516 25.2852C0.911765 25.5112 1.05257 25.7157 1.22889 25.8873C1.40521 26.0588 1.61359 26.1939 1.84213 26.2849C2.07067 26.3758 2.3149 26.4209 2.56086 26.4175H24.4417C24.6877 26.4209 24.9319 26.3758 25.1604 26.2849C25.389 26.1939 25.5973 26.0588 25.7737 25.8873C25.95 25.7157 26.0908 25.5112 26.188 25.2852C26.2853 25.0593 26.3371 24.8164 26.3404 24.5704V2.43125C26.3371 2.18529 26.2853 1.9424 26.188 1.71645C26.0908 1.49051 25.95 1.28593 25.7737 1.11441C25.5973 0.942887 25.389 0.807778 25.1604 0.716798C24.9319 0.625818 24.6877 0.580749 24.4417 0.584165ZM8.45086 22.2067H4.57586V10.5817H8.45086V22.2067ZM6.51336 8.95416C5.97895 8.95416 5.46642 8.74187 5.08854 8.36399C4.71065 7.9861 4.49836 7.47358 4.49836 6.93917C4.49836 6.40475 4.71065 5.89223 5.08854 5.51435C5.46642 5.13646 5.97895 4.92417 6.51336 4.92417C6.79713 4.89198 7.0845 4.9201 7.35666 5.00668C7.62881 5.09326 7.87961 5.23635 8.09263 5.42657C8.30564 5.6168 8.47608 5.84987 8.59278 6.11054C8.70947 6.3712 8.7698 6.65357 8.7698 6.93917C8.7698 7.22476 8.70947 7.50713 8.59278 7.76779C8.47608 8.02846 8.30564 8.26153 8.09263 8.45176C7.87961 8.64198 7.62881 8.78507 7.35666 8.87165C7.0845 8.95823 6.79713 8.98635 6.51336 8.95416ZM22.4267 22.2067H18.5517V15.9679C18.5517 14.405 17.9963 13.3846 16.5884 13.3846C16.1526 13.3878 15.7284 13.5244 15.3727 13.7762C15.017 14.0279 14.7471 14.3826 14.5992 14.7925C14.4981 15.0961 14.4543 15.4158 14.47 15.7354V22.1937H10.595C10.595 22.1937 10.595 11.6279 10.595 10.5687H14.47V12.2092C14.822 11.5983 15.334 11.0951 15.9508 10.7537C16.5677 10.4123 17.2659 10.2456 17.9704 10.2717C20.5538 10.2717 22.4267 11.9379 22.4267 15.5158V22.2067Z"
                  fill="#D2D2D6"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="border_1">
          <p className="main_para font_xs ff_gilroy_normal opacity_07 text-center py-2 mb-0">
            Copyright@{year} | All Rights Reserved
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
