import React, { useLayoutEffect } from "react";
import SolutionImghome from "../../assets/images/png/solution-tab-img-new.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
const Participant = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    let participant = gsap.timeline({
      scrollTrigger: {
        trigger: ".participant_sec",
        start: "top 50%",
        end: "center center",
        scrub: 1,
      },
    });
    participant.fromTo(
      ".participant_img",
      {
        top: "-5%",
      },
      {
        top: "20%",
      }
    );
  }, []);
  return (
    <>
      <div className="row pt-4 mt-1 mt-lg-5 py-sm-4 position-relative participant_sec">
        <div className="col-lg-8 m-auto m-lg-0">
          <div className="bg_blue rounded_border pb-lg-5 pt-1">
            <div className="max_w_participant ps-lg-5 px-4 mx-lg-4 my-2 pt-4 pb-4 pb-sm-5 py-md-5 mt-lg-4 mb-sm-2">
              <h3 className="font_xl color_white fw-normal open_sans mb-0">
                Participant Recruitment
              </h3>
              <p className="main_para ff_gilroy_normal fw-normal pt-3 pt-lg-4 mt-lg-1 pe-xl-5">
                Our recruitment process is expertly designed to identify and
                secure the most appropriate participants for your research
                project. We utilize a diverse range of recruitment methods, with
                our primary source being our extensive in-house panel of
                hospital staff, Healthcare professionals, patients with rare
                diseases and more.
              </p>
              <p className="main_para ff_gilroy_normal fw-normal mb-0 pe-xl-5">
                Our pre-screening process comprises of online, on-call and
                in-person evaluations varying on the fieldwork methodology.
              </p>
            </div>
            <div className="text-center pb-4 pb-sm-5 pb-lg-0 px-3 px-sm-0">
              <img
                className="w-100 rounded_border_images d-lg-none participant_img_max_width"
                src={SolutionImghome}
                alt="SolutionImghome"
              />
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-lg-block">
          <div className="position-absolute solution_tab_positioning participant_img">
            <img
              className="w-100 solution_tab_img_max_w rounded_border_images"
              src={SolutionImghome}
              alt="SolutionImghome"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Participant;
