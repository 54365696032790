import React, { useLayoutEffect, useState } from "react";
import { TabData } from "./common/Helper";
import SolutionsTab from "./Solution-Tab/SolutionsTab";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
const Solutions = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    toggleActions: "play complete none reverse",
  });
  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width:992px)": function () {
        let solutionanimate = gsap.timeline({
          scrollTrigger: {
            trigger: ".solution_sec",
            start: "top 30%",
            end: "center center",
            scrub: 1,
          },
        });
        solutionanimate.fromTo(
          ".solution_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "80px",
            width: "90vw",
            height: "300px",
          }
        );
      },
    });
    ScrollTrigger.matchMedia({
      "(min-width:576px) and (max-width:991px)": function () {
        let solutionanimate = gsap.timeline({
          scrollTrigger: {
            trigger: ".solution_sec",
            start: "top 70%",
            end: "center center",
            scrub: 1,
          },
        });
        solutionanimate.fromTo(
          ".solution_bg",
          {
            borderRadius: "0px",
            width: "100vw",
            height: "350px",
          },
          {
            borderRadius: "30px",
            width: "90vw",
            height: "250px",
          }
        );
      },
    });
    let solutionscrollanimate = gsap.timeline({
      scrollTrigger: {
        trigger: ".solution_sec",
        start: "top 50%",
        end: "center bottom",
        scrub: 1,
      },
    });
    solutionscrollanimate.fromTo(
      ".solution_text",
      {
        xPercent: -100,
        duration: 1,
      },
      {
        xPercent: "10",
      }
    );
  }, []);
  const [showtab, setShowtab] = useState(TabData[0]);
  return (
    <>
      <section className="py-xl-5 mt-xl-5 solution_sec " id="solutions">
        <div className="position-relative solution_bg  my-5 mx-auto">
          <img
            className="w-100 solution_bg border_rounded_custom_all_images_Mobile"
            src={showtab.imgurl}
            alt="solution-img"
          />
          <p
            eventKey="Fieldwork"
            className="font_2xl fw_500 ff_gilroy_medium text-white position-absolute health_p_pl solution_text">
            Solution
          </p>
        </div>
        <div className="container">
          <SolutionsTab setShowtab={setShowtab} showtab={showtab} />
        </div>
      </section>
    </>
  );
};

export default Solutions;
