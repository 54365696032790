import React from "react";

const CoreExecutive = () => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-center text-center flex-column px-2">
          <h3 className="fw-light ff_gilroy_light font_xl color_white mb-0  ">
            Core-Executive
          </h3>
          <p className="font_md text-white ff_gilroy_light font_sm pt-3 pt-lg-4 mt-lg-1">
            Our Core-Executive team is responsible for supervising the overall
            operations of the company, including global finance, strategic
            planning, the implementation of company growth, Human Resources, and
            marketing.
          </p>
        </div>
      </div>
    </>
  );
};

export default CoreExecutive;
