import React, { useLayoutEffect } from "react";
import streamlineImg from "../../assets/images/png/Streamline-img.png";
import IndustriesImg1 from "../../assets/images/png/industries-img-1.png";
import IndustriesImg2 from "../../assets/images/png/industries-img-2.png";
import IndustriesImg3 from "../../assets/images/png/industries-img-3.png";
import IndustriesHelp from "../../assets/images/png/industries-can-help-img.png";
import IndustriesBoxLine from "../../assets/images/png/BoxCenterLine.png";
import { gsap } from "gsap";

const Industries = () => {
  useLayoutEffect(() => {
    let industries = gsap.timeline({
      scrollTrigger: {
        trigger: ".industries_parent",
        start: "top 100%",
        end: "bottom center",
        // scrub: 3,
      },
    });
    industries
      .from(".industries_text", {
        xPercent: -100,
        autoAlpha: 0,
        duration: 1,
        ease: "back(2)",
      })
      .from(
        ".industries_para",
        {
          xPercent: 100,
          autoAlpha: 0,
          duration: 1,
          ease: "back(2)",
        },
        "=-.6"
      );

    let industriesImg = gsap.timeline({
      scrollTrigger: {
        trigger: ".industries_parent_img",
        start: "top 100%",
        end: "bottom center",
        scrub: 3,
      },
    });
    industriesImg.fromTo(
      ".industries_img",
      {
        yPercent: -10,
        duration: 1,
      },
      {
        yPercent: 0,
        duration: 1,
      }
    );
  }, []);
  return (
    <>
      <section className=" mb-md-5 mb-lg-0 pb-md-5 pb-lg-0 position-relative industries_parent">
        <img
          className="position-absolute industry_box_line d-none d-lg-block"
          src={IndustriesBoxLine}
          alt="industries"
        />
        <div className="container">
          <div className="padding_left_right px-md-0 mx-auto pb-5">
            <div className="industries_bg mx-auto mb-5 mb-lg-0">
              <div className="row justify-content-center justify-content-lg-start align-items-center pb-xl-5">
                <div className="col-10 col-md-6 col-lg-4">
                  <div className="industies_img_bg text-center">
                    <img
                      className="w-100"
                      src={IndustriesImg1}
                      alt="Industries-Img-1"
                    />
                    <p className="fs_lg fw-light ff_gilroy_light color_white pt-2 pt-md-4 mt-md-1 mb-0 px-2 px-md-0">
                      Recruitment
                    </p>
                  </div>
                </div>
                <div className="col-10 col-md-6 col-lg-4 pt-4 pt-md-0">
                  <div className="industies_img_bg text-center">
                    <img
                      className="w-100"
                      src={IndustriesImg2}
                      alt="Industries-Img-2"
                    />
                    <p className="fs_lg fw-light ff_gilroy_light color_white pt-2 pt-md-4 mt-md-1 mb-0 px-2 px-md-0">
                      Fieldwork
                    </p>
                  </div>
                </div>
                <div className="col-10 col-md-6 col-lg-4 pt-4 pt-lg-0">
                  <div className="industies_img_bg text-center">
                    <img
                      className="w-100"
                      src={IndustriesImg3}
                      alt="Industries-Img-3"
                    />
                    <p className="fs_lg fw-light ff_gilroy_light color_white pt-2 pt-md-4 mt-md-1 mb-0 px-2 px-md-0">
                      Data Services
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between pt-4 pt-lg-5 mt-lg-5 mb-5 mb-lg-0 industries_parent_img mx-xl-4 px-xl-1">
                <div className="col-sm-10 col-lg-4 m-auto m-lg-0 pt-xl-4">
                  <div className="pb-3">
                    <div className="industries_text">
                      <h3 className="fs_2xl color_white fw-light ff_gilroy_light mb-0 text-center text-lg-start">
                        Industries
                      </h3>
                    </div>
                    <ul className="pt-4 mt-lg-3 mb-0 p-0">
                      <div className="industries_li_box box_scale_hover">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Travel and Hospitality
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Technology
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Consumer Goods
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Retail
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Travel and Hospitality
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Education
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Energy and Utilities
                        </li>
                      </div>
                      <div className="industries_li_box box_scale_hover mt-3">
                        <li className="fs_lg fw-light ff_gilroy_light color_white">
                          Government
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-10 col-lg-7 col-xl-6 mx-auto mx-lg-0 pt-xl-4">
                  <div className="industries_para">
                    <p className="font-light ff_gilroy_light fs_xl color_white text-center text-lg-start pt-4 pt-lg-0 px-3 px-sm-0">
                      Industries we can help you in
                    </p>
                  </div>
                  <div className="industries_help_img industries_img">
                    <img
                      className="w-100 height_indudtries_img object_fit_cover rounded_border"
                      src={IndustriesHelp}
                      alt="streamline-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Industries;
